import * as React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Termini e Condizioni - Sterea Medicina e Viaggi" />

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column px-min py-12">
          <h2 className="mb-6">Termini e Condizioni</h2>

          <p>Gli Utenti che utilizzano i Servizi offerti da Sterea Medicina e Viaggi anche attraverso il proprio sito internet dichiarano di conoscere e accettare le presenti condizioni generali di contratto.</p>
          <p>​</p>
          <p>Proprietario del sito internet &ldquo;Sterea Medicina e Viaggi&rdquo; e dei relativi Servizi è la Soc. Sterea Medicina e Viaggi di Quattrini Stellina P.va/C.F:02378560441 con sede legale in Porto Sant'Elpidio (FM) 63821 via Brodolini 10.</p>
          <p>​</p>
          <p>Descrizione del servizio</p>
          <p>​</p>
          <p>Sterea Medicina e Viaggi è una società che offre servizi per tutti coloro che scelgono di eseguire trattamenti medico-chirurgici presso cliniche estere con le quali Stera medical travel collabora, in tal senso il sito internet della società Sterea svolge una funzione illustrativa dei servizi offerti da questa e delle cliniche estere con le quali collabora. La navigazione del sito non richiede alcuna registrazione da parte dell'utente ma un'accettazione espressa delle presenti condizioni che ove e qualora decida di avvalersi dei servizi della Sterea verrà al medesimo sottoposto per la sottoscrizione un contratto sul quale saranno indicati i servizi concordati con il cliente e relativo prezzo. Attraverso le funzionalità del sito, l'utente può vedere le cliniche estere con le quali Sterea collabora, gli interventi/trattamenti che queste eseguono ed altre informazioni generali di carattere medico. Il sito internet Sterea costituisce pertanto una infrastruttura che permette l'incontro e la comunicazione tra Utenti e Professionisti, senza che intervenga, con la semplice consultazione del medesimo, nessun rapporto giuridico che eventualmente verrà instaurato tra utente /Sterea e cliniche con le quali collabora con apposito contratto. La consultazione del sito Sterea pertanto è gratuito per gli utenti che rivestono la qualifica di Consumatori.</p>
          <p>​</p>
          <p>Il presente documento costituisce un accordo legale fra la società Sterea Medicina e Viaggi proprietaria del sito internet ed il consumatore, per &ldquo;Accordo legale&rdquo; si intende che i termini di tale accordo, una volta accettati dall'Utente, sono vincolanti per quest'ultimo. Per semplicità, &ldquo;Utente&rdquo;, &ldquo;tu&rdquo;, &ldquo;tuo&rdquo; e termini analoghi, sia al singolare che al plurale, si riferiscono a te, l&rsquo;Utente. &ldquo;Sterea Medicina e Viaggi&rdquo;, &ldquo;noi&rdquo;, &ldquo;nostro&rdquo; e termini simili si riferiscono alla società che possiede e gestisce Sterea, si riferisce al presente sito. &ldquo;Servizi&rdquo; indica ciò che Sterea offre ai propri clienti con la sottoscrizione di un &ldquo;Contratto&rdquo; concluso in lingua italiana. Altre definizioni possono essere rinvenute nella sezione &ldquo;Definizioni&rdquo; in calce a questo Contratto. "Professionista o clinica " la persona fisica o giuridica che presta professionalmente servizi di medicina e chirurgia estetica.</p>
          <p>​</p>
          <p>Contenuti forniti da terzi</p>
          <p>​</p>
          <p>Il Titolare del sito, la società Sterea Medicina e Viaggi quale proprietaria del sito internet effettua controlli e la moderazione sulle discussioni, contenuti o i link forniti da terzi mostrati sul proprio sito, controlli che vengono eseguiti a tutela dell'immagine della società Sterea, delle cliniche con le quali collabora e degli utenti che visitano il sito.</p>
          <p>La società Sterea si impegna ad intervenire a fronte di segnalazioni degli Utenti o di ordini impartiti da pubbliche autorità in relazione a contenuti ritenuti non pertinenti alle finalità del medesimo ovvero di quei contenuti ritenuti offensivi o addirittura illeciti.</p>
          <p>In particolare, il Titolare potrebbe sospendere o interrompere la visualizzazione dei contenuti nel caso in cui:</p>
          <ul>
          <li>
          <p>pervengano lamentele da parte di altri Utenti;</p>
          </li>
          <li>
          <p>riceva una segnalazione di violazione di diritti di proprietà intellettuale;</p>
          </li>
          <li>
          <p>ritenga di dover fare ciò in previsione di, o in esito a, azioni legali;</p>
          </li>
          <li>
          <p>tale azione sia richiesta da pubblica autorità; oppure</p>
          </li>
          <li>
          <p>ritenga che tale contenuto, rimanendo accessibile attraverso il sito Sterea potrebbe mettere a rischio gli Utenti, i terzi, la disponibilità del Servizio e/o lo stesso Titolare.​</p>
          </li>
          </ul>
          <p>​</p>
          <p>Diritti sui contenuti forniti</p>
          <p>​</p>
          <p>Sono riservati i diritti di tutti i contenuti pubblicati sul sito Sterea Medicina e Viaggi, ne è vietata pertanto la copia o riproduzione senza apposita autorizzazione. Sterea Medicina e Viaggi non sarà disponibile a condividere le proprie banche dati con alcuno se non espressamente autorizzato e per nessun altro fine se non quello del servizio offerto.</p>
          <p>​</p>
          <p>Servizi forniti da terze parti</p>
          <p>​</p>
          <p>Gli Utenti possono consultare liberamente tutte le informazioni pubblicate sul sito Sterea Medicina e Viaggi, ma devono prima aver preso visione dei termini e condizioni ed averle accettate. In nessuna circostanza il Titolare potrà essere ritenuto responsabile in relazione al corretto funzionamento o alla disponibilità dei servizi forniti.</p>
          <p>In particolare, l'Utente riconosce ed accetta le condizioni sopra elencate. La società Sterea Medicina e Viaggi non garantisce né risponde per i servizi prestati dalle cliniche convenzionate. Si invita l'Utente a segnalare qualsiasi abuso o uso improprio del sito Sterea Medicina e Viaggi da parte di chiunque delle condizioni sopra descritte.</p>
          <p>​</p>
          <p>Utilizzo non consentito</p>
          <p>​</p>
          <p>Il Servizio dovrà essere utilizzato secondo quanto stabilito nei Termini. Gli Utenti non possono:</p>
          <ul>
          <li>
          <p>effettuare copia, reverse engineering, decompilare, disassemblare, modificare o creare lavori derivati basati sul contenuto del sito Sterea Medicina e Viaggi su qualunque porzione di essa;</p>
          </li>
          <li>
          <p>aggirare i sistemi informatici usati da Sterea Medicina e Viaggi o dai suoi licenziatari per proteggere il contenuto accessibile tramite di essa;</p>
          </li>
          <li>
          <p>copiare, conservare, modificare, cambiare, preparare lavori derivati o alterare in qualunque modo qualunque dei contenuti forniti dal sito della Sterea Medicina e Viaggi ;</p>
          </li>
          <li>
          <p>utilizzare qualunque robot, spider, applicazione di ricerca e/o di reperimento di siti, ovvero qualunque altro dispositivo, processo o mezzo automatico per accedere, recuperare, effettuare scraping o indicizzare qualunque porzione di Sterea Medicina e Viaggi -sito internet-o dei suoi contenuti;</p>
          </li>
          <li>
          <p>affittare, licenziare o sublicenziare-sito internet di Sterea Medicina e Viaggi;</p>
          </li>
          <li>
          <p>diffamare, offendere, molestare, mettere in atto pratiche minatorie,</p>
          <p>minacciare o in altro modo violare i diritti di altri;</p>
          </li>
          <li>
          <p>diffondere o pubblicare contenuti illegali, osceni, illegittimi, diffamatori o</p>
          <p>inappropriati;</p>
          </li>
          <li>
          <p>appropriarsi illecitamente dell&rsquo;account in uso presso altro Utente;</p>
          </li>
          <li>
          <p>registrarsi o utilizzare il Servizio al fine di approcciare gli Utenti per</p>
          <p>promuovere, vendere o pubblicizzare in qualsiasi modo prodotti o servizi</p>
          <p>di qualsivoglia tipo attraverso il sito di Sterea Medicina e Viaggi; ;</p>
          </li>
          <li>
          <p>utilizzare il sito internet di Sterea Medicina e Viaggi in qualunque altra modalità impropria tale da violare i Termini.</p>
          </li>
          </ul>
          <p>​</p>
          <p>Risarcimento e limitazione di responsabilità</p>
          <p>Manleva</p>
          <p>​</p>
          <p>L'Utente si impegna a mantenere indenne il Titolare (nonché le eventuali società dallo stesso controllate o affiliate, i suoi rappresentanti, amministratori, agenti, licenziatari, partner e dipendenti), da qualsiasi obbligo o responsabilità, incluse le eventuali spese legali sostenute per difendersi in giudizio, che dovessero sorgere a fronte di danni provocati ad altri Utenti o a terzi, in relazione ai contenuti caricati online, alla violazione della legge o dei termini delle presenti condizioni di servizio.</p>
          <p>In particolare, l'Utente riconosce ed accetta che Sterea Medicina e Viaggi attraverso il suo sito non interviene né è parte nel rapporto giuridico eventualmente instaurato tra Utente e Professionista/clinica. La Società Sterea Medicina e Viaggi non risponde pertanto dell'operato dei Professionisti/cliniche, atteso che trattasi di eventuale responsabilità medica e che come tale riguarda il professionista medico e la clinica presso la quale opera.</p>
          <p>Si invitano gli Utenti a segnalare qualsiasi abuso, uso improprio o violazione, anche se solo sospetta, agli estremi di contatto esposti in apertura del presente documento.</p>
          <p>Sul sito della soc. Sterea Medicina e Viaggi possono essere presenti articoli divulgativi a contenuto medico - scientifico. L&rsquo;Utente riconosce e accetta che i contenuti o le informazioni presenti all&rsquo;interno del sito internet della Sterea Medicina e Viaggi vengono forniti a mero scopo informativo o divulgativo e non sono in alcun caso da intendersi come consulenza professionale né costituiscono in alcun modo prescrizione di un trattamento medico o</p>
          <p>sostituiscono le indicazioni fornite da professionisti della salute. L&rsquo;Utente dichiara di aver ben compreso e di accettare, senza riserva alcuna, che i contenuti di questa natura pubblicati sul sito in parola sono usati a suo rischio e manleva il Titolare da qualsivoglia responsabilità in relazione a danni che potrebbero conseguire dalla scorretta interpretazione, e possibile conseguente uso, di tali contenuti.</p>
          <p>​</p>
          <p>Disposizioni comuni</p>
          <p>Interruzione del Servizio</p>
          <p>​</p>
          <p>Per garantire agli Utenti la migliore fruizione possibile del Servizio, il Titolare, si riserva il diritto di interrompere il Servizio per necessità di manutenzione o aggiornamento del sistema, informando gli Utenti attraverso aggiornamenti costanti in merito.</p>
          <p>​</p>
          <p>Rivendita del Servizio</p>
          <p>​</p>
          <p>Gli Utenti non sono autorizzati a riprodurre, duplicare, copiare, vendere, rivendere o sfruttare qualunque porzione del sito internet di proprietà della Sterea Medicina e Viaggi e dei suoi Servizi senza il previo permesso scritto da parte del Titolare, garantito direttamente o tramite uno specifico programma di rivendita.</p>
          <p>​</p>
          <p>Privacy policy</p>
          <p>​</p>
          <p>Per informazioni sull'utilizzo dei dati personali, gli Utenti devono fare riferimento alla privacy policy di Sterea Medicina e Viaggi presente all'interno del sito.</p>
          <p>​</p>
          <p>Diritti di proprietà intellettuale</p>
          <p>​</p>
          <p>Tutti i marchi dell'Applicazione, figurativi o nominativi, e tutti gli altri segni, nomi commerciali, marchi di servizio, marchi denominativi, denominazioni commerciali, illustrazioni, immagini, loghi che appaiono sul sito della Sterea Medicina e Viaggi sono e rimangono di proprietà esclusiva del Titolare o dei suoi licenziatari e sono protetti dalle leggi vigenti sui marchi e dai relativi trattati internazionali.</p>
          <p>Tutti i marchi e tutti gli altri segni, nomi commerciali, marchi di servizio, marchi denominativi, denominazioni commerciali, illustrazioni, immagini, loghi riguardanti terze parti ed i contenuti pubblicati da tali terzi sul sito internet Sterea Medicina e Viaggi sono e rimangono di proprietà esclusiva o nella disponibilità di detti terzi e dei loro licenziatari e sono protetti dalle leggi vigenti sui marchi e dai relativi trattati internazionali. Il Titolare non possiede la titolarità di tali privative intellettuali e può servirsene solo entro i limiti ed in conformità ai contratti conclusi con tali terze parti e per gli scopi ivi delineati.</p>
          <p>​</p>
          <p>Licenza d&rsquo;uso del software</p>
          <p>​</p>
          <p>Sterea Medicina e Viaggi garantisce all'Utente una licenza revocabile, personale, senza limiti territoriali, gratuita, non trasferibile e non esclusiva di utilizzo del</p>
          <p>Software e/o di qualsiasi altro strumento tecnico implementato nel Servizio. Questa licenza ha il solo scopo di permettere all'Utente l'utilizzo del Servizio, nei limiti e nel rispetto del Contratto, delle procedure API e/o di qualsiasi norma applicabile.</p>
          <p>L'Utente è tenuto a utilizzare il Servizio nel rispetto dei diritti di proprietà intellettuale e industriale del Titolare.</p>
          <p>Il Software relativo al Servizio, così come ogni altro diritto d'autore o diritto di proprietà intellettuale, è di esclusiva proprietà del Titolare e/o dei suoi licenziatari. L'Utente non acquisisce alcun diritto o titolo sul Software e acconsente al suo utilizzo in via non esclusiva e non trasferibile, limitatamente alla durata di utilizzo del Servizio, tramite connettività remota, tramite Internet, per la fornitura dei Servizi nel rispetto del Contratto. Il diritto di utilizzo del Software non concede alcun diritto sul codice sorgente originale. Tutte le tecniche, gli algoritmi e i procedimenti contenuti nel Software e nella relativa documentazione sono informazioni protette dal diritto d&rsquo;autore e sono di proprietà del Titolare o dei suoi licenziatari e, pertanto, non possono essere usati in alcun modo per scopi diversi da quelli indicati nel Contratto.</p>
          <p>Tutti i diritti e le licenze concessi all'Utente dovranno considerarsi revocati allo spirare del Contratto.</p>
          <p>​</p>
          <p>Modifiche ai presenti Termini</p>
          <p>​</p>
          <p>Il Titolare si riserva il diritto di apportare modifiche ai Termini in qualunque momento, dandone avviso all'Utente mediante la sua pubblicazione all'interno del sito internet Sterea Medicina e Viaggi. L'Utente che continui ad utilizzare il sito internet di Sterea Medicina e Viaggi successivamente alla pubblicazione delle modifiche, accetta senza riserva i nuovi Termini.</p>
          <p>​</p>
          <p>Cessione del contratto</p>
          <p>​</p>
          <p>Il Titolare si riserva il diritto di trasferire, cedere, disporre per novazione o subappaltare tutti o alcuni dei diritti o obblighi derivanti dai Termini, purché i diritti dell&rsquo;Utente qui previsti non siano pregiudicati.</p>
          <p>L&rsquo;Utente non potrà cedere o trasferire in alcun modo i propri diritti o obblighi ai sensi dei Termini senza l&rsquo;autorizzazione scritta del Titolare.</p>
          <p>​</p>
          <p>Comunicazioni</p>
          <p>​</p>
          <p>Tutte le comunicazioni relative a Sterea Medica Travel devono essere inviate utilizzando le informazioni di contatto indicate nel Contratto.</p>
          <p>​</p>
          <p>Inefficacia e nullità parziale</p>
          <p>​</p>
          <p>Qualora una qualsiasi clausola dei Termini dovesse risultare nulla, non valida o inefficace, la suddetta clausola sarà eliminata mentre le restanti clausole non saranno da ciò condizionate e rimarranno pienamente efficaci.</p>
          <p>​</p>
          <p>Legge applicabile e Foro competente</p>
          <p>​</p>
          <p>I presenti Termini e tutte le controversie in merito ad esecuzione, interpretazione e validità del presente contratto sono soggette alla legge, alla giurisdizione dello Stato e alla competenza esclusiva del Tribunale di Fermo (FM). Fa eccezione il foro esclusivo del consumatore, qualora la legge lo preveda.</p>
          <p>​</p>
          <p>Definizioni</p>
          <p>​</p>
          <p>Servizio</p>
          <p>​</p>
          <p>Il servizio offerto da Sterea Medicina e Viaggi come descritto da questi Termini ed all'interno di Sterea Medicina e Viaggi.</p>
          <p>​</p>
          <p>Termini e Condizioni (o Termini)</p>
          <p>​</p>
          <p>Le presenti condizioni generali di servizio, che costituiscono un accordo legalmente vincolante fra l'Utente ed il Titolare.</p>
          <p>​</p>
          <p>Utente</p>
          <p>​</p>
          <p>La persona fisica o giuridica che utilizza il Servizio.</p>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;